<template>
  <div class="school-data-teacher">
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          class="my-6"
          title="Data Guru"
          subtitle="Halaman untuk melihat total seluruh guru"
          :total-items="totalItems"
          :headers="headers"
          :items="teachers"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-detail="detailCertificate"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else>
    </SkeletonLoaderTable>

    <ModalDialog
      :visible="modalCertificate"
      header="Sertifikat Guru"
      :hidden-actions="false"
      @close="modalCertificate = false"
      @close-modal="modalCertificate = false"
    >
      <template
        v-if="certificates.length"
        v-slot:body
      >
        <div
          v-for="certificate,index in certificates"
          :key="index"
        >
          <div
            class="d-flex py-3 align-center justify-space-between"
          >
            <div>
              <p class="mb-0 font-semibold">
                {{ certificate.name }}
              </p>
              <span>Nomor : {{ certificate.description }}</span>
            </div>
            <div>
              <v-chip
                v-if="checkExtension(certificate.file) === 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                :href="certificate.file"
                target="_blank"
              >
                Lihat Sertifikat
              </v-chip>
              <v-chip
                v-if="checkExtension(certificate.file) !== 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                @click="showCertificate(certificate.file)"
              >
                Lihat Sertifikat
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <template
        v-else
        v-slot:body
      >
        Belum ada sertifikat
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="800"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiClose } from '@mdi/js'
import DataTablePagination from '../../components/DataTablePagination.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'SchoolDataTeacher',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    ImageOverlay,
    SkeletonLoaderTable,
  },
  data() {
    return {
      teachers: [],
      student: {},
      icons: { mdiArrowLeft, mdiClose },
      search: '',
      modalCertificate: false,
      imageOverlay: false,
      overlay: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      teacher_uuid: '',
      service: 'teacher',
      headers: [
        { text: '#', value: 'index' },
        { text: 'NIP', value: 'employee.nip' },
        { text: 'NIK', value: 'employee.nik' },
        { text: 'Nama', value: 'employee.name' },
        { text: 'Jabatan', value: 'employee.status_work' },
        { text: 'Foto', value: 'thumbnail' },
        { text: 'Sertifikat', value: 'employee.total_certificate' },
        { text: '', value: 'detail' },
      ],
      certificates: [],
      certificate: {
        file: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getTeacher()
      },
    },
    search: {
      handler() {
        this.getTeacher()
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getTeacher()
  },
  methods: {
    async getTeacher(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        search: this.search,
        page: this.page,
      }).then(({ data }) => {
        this.teachers = data.data.map((teacher, index) => ({
          index: index + 1,
          ...teacher,
        }))
        this.teachers.forEach(el => {
          this.teacher_uuid = el.uuid
        })
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
    async detailCertificate(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.certificates = data.data.employee.certificate
        this.certificate.file = this.certificates.forEach(el => {
          this.certificate.file = el
        })
      })
      this.modalCertificate = true
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.imageOverlay = true
    },
    closeImage() {
      this.overlay = false
      this.imageOverlay = false
      this.modalCertificate = true
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
